import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
import { type Contenttype, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type ContentTypeResponse = ResponseType<'/contenttype', 'get'>

type ContentTypeItem = ArrElement<ContentTypeResponse['items']>

type PostContentTypeIdBody = RequestBody<'/contenttype/0', 'post'>

type CreateContentTypeBody = RequestBody<'/contenttype', 'post'>

type MergeContenttypeBody = RequestBody<'/contenttype/merge/oldnew', 'post'>

function apiGetContentTypes() {
    return apiCall('/contenttype', 'get', { page: 1, perPage: 1000 }, null)
}
function apiGetContentTypeById(id: number) {
    return apiCall(`/contenttype/${id}`, 'get', null, null)
}
function apiUpdateContentType(id: number, body: PostContentTypeIdBody) {
    return apiCall(`/contenttype/${id}`, 'post', null, body)
}

function apiDeleteContentType(id: number) {
    return apiCall(`/contenttype/${id}`, 'delete', null, null)
}

function apiCreateContentType(body: CreateContentTypeBody) {
    return apiCall(`/contenttype`, 'post', null, body)
}

function apiMergeContenttype(body: MergeContenttypeBody) {
    return apiCall('/contenttype/merge/oldnew', 'post', null, body || {})
}

export { apiGetContentTypes, apiUpdateContentType, apiCreateContentType, apiDeleteContentType, apiMergeContenttype }
export type { ContentTypeResponse, ContentTypeItem, CreateContentTypeBody, MergeContenttypeBody }

//REVIEW CRUQ
const ContentTypeApi = {
    get: apiGetContentTypeById,
    create: apiCreateContentType,
    update: apiUpdateContentType,
    delete: apiDeleteContentType,
} as unknown as Api<Contenttype>

export default ContentTypeApi
