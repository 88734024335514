import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
//REVIEW CRUQ
import { type Target, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type TargetResponse = ResponseType<'/target', 'get'>

type TargetItem = ArrElement<TargetResponse['items']>

type PostTargetIdBody = RequestBody<'/target/0', 'post'>

type CreateTargetBody = RequestBody<'/target', 'post'>

type MergeTargetBody = RequestBody<'/target/merge/oldnew', 'post'>

// REVIEW zodra ik op save druk verspring formatting op een inconsistente manier. Ik heb de breedte in prettier omgezet naar 800. Overleg a.u.b

function apiGetTargets() {
    return apiCall('/target', 'get', { page: 1, perPage: 1000 }, null)
}
function apiGetTargetById(id: number) {
    return apiCall(`/target/${id}`, 'get', null, null)
}
function apiUpdateTarget(id: number, body: PostTargetIdBody) {
    return apiCall(`/target/${id}`, 'post', null, body)
}

function apiDeleteTarget(id: number) {
    return apiCall(`/target/${id}`, 'delete', null, null)
}

function apiCreateTarget(body: CreateTargetBody) {
    return apiCall(`/target`, 'post', null, body)
}

function apiMergeTarget(body: MergeTargetBody) {
    return apiCall('/target/merge/oldnew', 'post', null, body || {})
}

export { apiGetTargets, apiUpdateTarget, apiCreateTarget, apiDeleteTarget, apiMergeTarget }
export type { TargetResponse, TargetItem, CreateTargetBody, PostTargetIdBody, MergeTargetBody }

//REVIEW CRUQ
const TargetApi = {
    get: apiGetTargetById,
    create: apiCreateTarget,
    update: apiUpdateTarget,
    delete: apiDeleteTarget,
} as Api<Target>

export default TargetApi
