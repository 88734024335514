import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
//REVIEW CRUQ
import { type Audience, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type AudienceResponse = ResponseType<'/audience', 'get'>

type AudienceItem = ArrElement<AudienceResponse['items']>

type PostAudienceIdBody = RequestBody<'/audience/0', 'post'>

type CreateAudienceBody = RequestBody<'/audience', 'post'>

type MergeAudienceBody = RequestBody<'/audience/merge/oldnew', 'post'>

function apiGetAudiences() {
    return apiCall('/audience', 'get', { page: 1, perPage: 1000 }, null)
}
function apiGetAudiencesById(id: number) {
    return apiCall(`/audience/${id}`, 'get', null, null)
}
function apiUpdateAudience(id: number, body: PostAudienceIdBody) {
    return apiCall(`/audience/${id}`, 'post', null, body)
}

function apiDeleteAudience(id: number) {
    return apiCall(`/audience/${id}`, 'delete', null, null)
}

function apiCreateAudience(body: CreateAudienceBody) {
    return apiCall(`/audience`, 'post', null, body)
}

function apiMergeAudience(body: MergeAudienceBody) {
    return apiCall('/audience/merge/oldnew', 'post', null, body || {})
}

export { apiGetAudiences, apiUpdateAudience, apiCreateAudience, apiDeleteAudience, apiMergeAudience }
export type { AudienceResponse, AudienceItem, CreateAudienceBody, MergeAudienceBody }

//REVIEW CRUQ
const AudienceApi = {
    get: apiGetAudiencesById,
    create: apiCreateAudience,
    update: apiUpdateAudience,
    delete: apiDeleteAudience,
} as Api<Audience>

export default AudienceApi
